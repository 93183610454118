import React from 'react'
import './AppLogo.css'
class AppLogo extends React.Component {
  render() {
    return (
      <svg
        style={{ height: '2rem' }}
        version="1.1"
        id="logo"
        x="0px"
        y="0px"
        viewBox="0 0 636.4 127.9"
      >
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -6.8276 116.0424)"
          className="logo-st0"
          cx="97.1"
          cy="63.9"
          rx="18.7"
          ry="18.7"
        ></ellipse>
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -32.9704 70.7617)"
          className="logo-st0"
          cx="44.8"
          cy="63.9"
          rx="18.7"
          ry="18.7"
        ></ellipse>
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -85.2561 70.7617)"
          className="logo-st0"
          cx="18.7"
          cy="109.2"
          rx="18.7"
          ry="18.7"
        ></ellipse>
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -32.9704 161.3231)"
          className="logo-st0"
          cx="123.2"
          cy="109.2"
          rx="18.7"
          ry="18.7"
        ></ellipse>
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -59.1133 116.0424)"
          className="logo-st0"
          cx="70.9"
          cy="109.2"
          rx="18.7"
          ry="18.7"
        ></ellipse>
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 19.3153 70.7617)"
          className="logo-st0"
          cx="70.9"
          cy="18.7"
          rx="18.7"
          ry="18.7"
        ></ellipse>
        <line
          className="logo-st1"
          x1="72.6"
          y1="16.5"
          x2="123.2"
          y2="109.1"
        ></line>
        <polyline
          className="logo-st1"
          points="18.7,109.2 44.8,63.9 71.1,109.1 123.2,109.1 "
        ></polyline>
        <g>
          <path
            className="logo-st2"
            d="M185,88c-9.6,0-15.8-5.4-15.8-13.8c0-9.9,6.8-15.2,19.7-15.2H200v-4.3c0-5.4-3.3-8.3-9.7-8.3
		c-5.8,0-9.4,2.6-9.7,6.9h-9c0.2-9.7,7-15.3,18.7-15.3c12.1,0,19,5.8,19,16v32.9h-5.2l-4.2-5.8l-0.4,0.5C196,85.9,191.2,88,185,88z
		M188.8,65.8c-6.9,0-10.3,2.4-10.3,7.2c0,3,1.5,6.6,8.6,6.6c4.9,0,9.3-1.9,12.8-5.7l0.1-0.1v-7.9H188.8z"
          ></path>
        </g>
        <g>
          <path
            className="logo-st2"
            d="M224.6,101.8V39.1h5.2l4.1,5.8l0.4-0.5c3.6-4.3,8.2-6.4,14.1-6.4c4.9,0,8.8,1.5,11.9,4.5
		c3.2,3,4.8,7.4,4.8,12.9v15.2c0,10.9-6.4,17.4-17.1,17.4c-5.8,0-10.2-1.9-13.3-5.7l-0.9-1.1v20.7H224.6z M246.3,46.3
		c-4.2,0-8.2,2.1-12.3,6.4l-0.1,0.1V73l0.1,0.1c4,4.3,8.1,6.4,12.2,6.4c5.6,0,9.7-4.1,9.7-9.8V56.1C255.9,50.4,252,46.3,246.3,46.3z
		"
          ></path>
        </g>
        <g>
          <path
            className="logo-st2"
            d="M279.6,101.8V39.1h5.2l4.1,5.8l0.4-0.5c3.6-4.3,8.2-6.4,14.1-6.4c4.9,0,8.8,1.5,11.9,4.5
		c3.2,3,4.8,7.4,4.8,12.9v15.2c0,10.9-6.4,17.4-17.1,17.4c-5.8,0-10.2-1.9-13.3-5.7l-0.9-1.1v20.7H279.6z M301.3,46.3
		c-4.2,0-8.2,2.1-12.3,6.4l-0.1,0.1V73l0.1,0.1c4,4.3,8.1,6.4,12.2,6.4c5.6,0,9.7-4.1,9.7-9.8V56.1
		C310.9,50.4,306.9,46.3,301.3,46.3z"
          ></path>
        </g>
        <g>
          <path
            className="logo-st2"
            d="M427.3,88c-12.9,0-20.6-6.3-20.9-16.9h9.3c0.3,5.3,5,8.5,12.4,8.5c5.9,0,9.5-2.2,9.5-5.8
		c0-2.7-1.9-4.5-5.7-5.5l-12.7-3.3c-7.8-2.1-11.3-5.9-11.3-12.5c0-8.8,7.4-14.5,18.8-14.5c11.9,0,19.1,6.1,19.4,16.4h-9.3
		c-0.3-5-4.3-8.1-10.7-8.1c-5.5,0-8.8,2.1-8.8,5.7c0,2.4,1.9,4.2,5.7,5.2l12.6,3.1c7.7,1.9,11.4,6.1,11.4,12.6c0,4.4-1.8,8-5.3,10.9
		C438.1,86.6,433.4,88,427.3,88z"
          ></path>
        </g>
        <g>
          <path
            className="logo-st2"
            d="M478.1,88c-12.2,0-19.8-6.7-19.8-17.6V55.6c0-10.9,7.6-17.6,19.8-17.6c12.1,0,19.4,6.5,19.4,17.4v0.1h-9.3
		v-0.1c0-5.6-3.9-9-10.1-9c-6.7,0-10.5,3.3-10.5,9v15.2c0,5.7,3.8,9,10.5,9c6.2,0,10.1-3.5,10.1-9v-0.3h9.3v0.2
		c0,5.3-1.8,9.7-5.4,12.8C488.6,86.4,484,88,478.1,88z"
          ></path>
        </g>
        <g>
          <path
            className="logo-st2"
            d="M524.7,88c-9.6,0-15.8-5.4-15.8-13.8c0-9.9,6.8-15.2,19.7-15.2h11.2v-4.3c0-5.4-3.3-8.3-9.7-8.3
		c-5.8,0-9.4,2.6-9.7,6.9h-9c0.2-9.7,7-15.3,18.7-15.3c12.1,0,19,5.8,19,16v32.9h-5.2l-4.2-5.8l-0.4,0.5
		C535.6,85.9,530.9,88,524.7,88z M528.5,65.8c-6.9,0-10.3,2.4-10.3,7.2c0,3,1.5,6.6,8.6,6.6c4.9,0,9.3-1.9,12.8-5.7l0.1-0.1v-7.9
		H528.5z"
          ></path>
        </g>
        <g>
          <path
            className="logo-st2"
            d="M616.8,88c-12.5,0-20.3-6.7-20.3-17.4v-15c0-5.4,1.8-9.5,5.5-12.7c3.8-3.2,8.8-4.8,14.9-4.8
		c12.1,0,19.6,6.7,19.6,17.4v10.5h-30.6v4.5c0,5.7,4.2,9.2,11,9.2c5.6,0,9-2.2,10.1-6.5h9.4C635,82.6,627.9,88,616.8,88z
		M616.4,46.3c-6.7,0-10.6,3.4-10.6,9V59h21.2v-3.6C627.1,49.7,623.1,46.3,616.4,46.3z"
          ></path>
        </g>
        <g>
          <polygon
            className="logo-st2"
            points="389,31.1 389.3,31.1 389.3,21.8 389,21.8 380,21.8 379.7,21.8 379.7,31.1 380,31.1 	"
          ></polygon>
          <polygon
            className="logo-st2"
            points="399.6,78.8 389.3,78.8 389.3,47.1 389.3,39.1 389,39.1 369.3,39.1 369,39.1 369,47.1 369.3,47.1 
		379.7,47.1 379.7,78.8 369.3,78.8 369,78.8 369,86.8 369.3,86.8 399.6,86.8 399.9,86.8 399.9,78.8 	"
          ></polygon>
        </g>
        <polygon
          className="logo-st2"
          points="349.8,78.8 349.8,29.8 349.8,21.8 329.6,21.8 329.6,29.8 340.5,29.8 340.5,78.8 329.6,78.8 329.6,86.8 
	360.2,86.8 360.2,78.8 "
        ></polygon>
        <polygon
          className="logo-st2"
          points="578.7,78.8 578.7,29.8 578.7,21.8 558.5,21.8 558.5,29.8 569.4,29.8 569.4,78.8 558.5,78.8 558.5,86.8 
	589.1,86.8 589.1,78.8 "
        ></polygon>
      </svg>
    )
  }
}

export default AppLogo
