import React from 'react'
import AppLogo from '../AppLogo/AppLogo'
import './Footer.css'

import KPT from '../../img/KPT.png'
import AWS from '../../img/AWS.png'
import SoDA from '../../img/SoDA.png'
import BNI from '../../img/BNI.svg'

const TwitterLink = class extends React.Component {
  render() {
    return (
      <a href="https://twitter.com/appliscale" className="footer-social-link">
        <svg version="1.1" className="social-link" viewBox="0 0 24 24">
          <path
            className="stroke"
            d="M10.2,0.4L0.3,6.1v11.3l9.8,5.7l9.8-5.7V6.1L10.2,0.4z"
          ></path>
          <path
            className="logo-sign"
            d="M15.3,9.9c0.2,3.4-2.4,7.1-6.8,7.1c-1.3,0-2.6-0.4-3.7-1.1c1.3,0.1,2.5-0.2,3.5-1c-1,0-1.9-0.7-2.2-1.7
                                c0.4,0.1,0.7,0.1,1.1,0c-1.1-0.2-1.9-1.3-1.9-2.4C5.6,11,6,11.1,6.4,11.1C5.3,10.4,5,9,5.7,7.9c1.2,1.4,2.9,2.4,4.9,2.5
                                c-0.3-1.5,0.8-2.9,2.3-2.9c0.7,0,1.3,0.3,1.7,0.8c0.5-0.1,1.1-0.3,1.5-0.6c-0.2,0.6-0.6,1-1,1.3c0.5-0.1,0.9-0.2,1.4-0.4
                                C16.2,9.1,15.8,9.5,15.3,9.9z"
          ></path>
        </svg>
      </a>
    )
  }
}

const FacebookLink = class extends React.Component {
  render() {
    return (
      <a
        href="https://pl-pl.facebook.com/appliscale/"
        className="footer-social-link"
      >
        <svg version="1.1" className="social-link" viewBox="0 0 24 24">
          <path
            className="stroke"
            d="M10.2,0.4L0.3,6.1v11.3l9.8,5.7l9.8-5.7V6.1L10.2,0.4z"
          ></path>
          <path
            className="logo-sign"
            d="M12.7,8.8h-1c-0.4,0-0.5,0.2-0.5,0.6v0.9h1.5l-0.2,1.5h-1.3v5.1H9.1v-5.1H7.6v-1.5h1.5V8.6c0-1.3,0.7-2,2.2-2h1.4V8.8z"
          ></path>
        </svg>
      </a>
    )
  }
}

const LinkedLink = class extends React.Component {
  render() {
    return (
      <a
        href="https://pl.linkedin.com/company/appliscale"
        className="footer-social-link"
      >
        <svg version="1.1" className="social-link" viewBox="0 0 24 24">
          <path
            className="stroke"
            d="M10.2,0.4L0.3,6.1v11.3l9.8,5.7l9.8-5.7V6.1L10.2,0.4z"
          ></path>
          <rect
            className="logo-sign"
            x="6.2"
            y="9.7"
            width="2"
            height="6"
          ></rect>
          <ellipse
            className="logo-sign"
            cx="7.2"
            cy="7.7"
            rx="1.1"
            ry="1.1"
          ></ellipse>
          <path
            className="logo-sign"
            d="M15.2,15.7h-2v-2.9c0-1.9-2-1.7-2,0v2.9h-2v-6h2v1.1c0.9-1.6,4-1.7,4,1.5V15.7z"
          ></path>
        </svg>
      </a>
    )
  }
}

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="p-4 bg-white">
        <div className="flex flex-col space-y-3">
          <div className="flex flex-col md:flex-row items-center justify-between space-x-4 space-y-4">
            <div className="flex flex-col items-center justify-between space-y-1 text-gray-600">
              <AppLogo />
              <a href="mailto:info@appliscale.io">info@appliscale.io</a>
              <a href="tel:+48 669 202 462">+48 669 202 462</a>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              <img src={KPT} alt="KTP" className="h-12" />
              <img src={AWS} alt="AWS" className="h-12" />
              <img src={SoDA} alt="SoDA" className="h-12" />
              <img src={BNI} alt="BNI" className="h-12" />
            </div>

            <div className="flex flex-col items-center justify-between space-y-1">
              <div className="flex justify-center items-center space-x-1 mt-4 ">
                <FacebookLink />
                <LinkedLink />
                <TwitterLink />
              </div>
              <div className="text-xs text-center max-w-prose">
                All rights reserved {new Date().getFullYear()}©
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center text-xs text-center text-gray-400">
            <p className="max-w-4xl	 text-xs text-center ">
              Appliscale sp. z o.o. seated in Kraków, address: ul. prof. Michała
              Życzkowskiego 14, 31-864 Kraków District Court for the city of
              Krakow, 11th Commercial Division of the National Court Register
              registration number: 0000592380 Tax Identification Number:
              9452189348 share capital: 100 000 zł.
            </p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
