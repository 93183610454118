import React from 'react'
import AppLogo from '../AppLogo/AppLogo'
import { Link } from 'gatsby'
import './Navbar.css'

const NavLink = class extends React.Component {
  render() {
    return (
      <Link
        className="mt-4 md:mt-0 md:ml-5 group"
        to={this.props.to}
        activeClassName="active"
      >
        <span className="navbar-link-text">{this.props.name}</span>
      </Link>
    )
  }
}

const Navigation = class extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <NavLink name="Home" to="/"></NavLink>
        <NavLink name="Products" to="/products"></NavLink>
        <NavLink name="About" to="/about"></NavLink>
        <NavLink name="Blog" to="/blog"></NavLink>
        <NavLink name="Contact" to="/contact"></NavLink>
      </div>
    )
  }
}

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  onButtonClicked = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    return (
      <nav className="bg-white">
        <div className="p-4 flex md:flex-row flex-col justify-between ">
          <div className="flex flex-row justify-between items-center">
            <div className="md:text-center">
              <a href="/">
                <AppLogo />
              </a>
            </div>
            <div className="md:hidden">
              <button
                className="flex items-center py-2 px-3 text-appliscale rounded border border-appliscale"
                onClick={() => this.onButtonClicked()}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
              </button>
            </div>
          </div>
          <Navigation className="hidden md:flex flex-row items-center" />
        </div>
        <Navigation
          className={
            'pl-4 pb-4 flex md:hidden flex-col fixed w-screen z-40 bg-gray-300 ' +
            (this.state.active ? '' : 'hidden')
          }
        />
      </nav>
    )
  }
}

export default Navbar
